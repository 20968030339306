import { AnimatePresence } from 'framer-motion'
import { Route, Routes, useLocation } from 'react-router'
import styled, { css } from 'styled-components'

import Checkout from './features/Checkout'
import Landing from './features/Landing'
import Listing from './features/Listing'
import Navigation from './features/Navigation'
import Product from './features/Product'
import Scanner from './features/Scanner'
import Search from './features/Search'
import NavBar from './layout/NavBar'
import useLayout from './layout/useLayout'
import { Cover, TransitionSecondary } from './lib/mixins'

const AppWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const AppHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: var(--size-nav-bar);
  z-index: 100;

  &:before {
    ${Cover()}
    background-color: var(--color-global);
    transform: scaleY(0);
    visibility: hidden;
    transform-origin: center top;
    backface-visibility: hidden;
    ${TransitionSecondary('transform, visibility')}
  }

  ${props =>
    props.theme === 'DARK' &&
    css`
      color: var(--color-inverted);

      &:before {
        transform: scaleY(1);
        visibility: visible;
      }
    `};
`

const AppContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  bottom: 0;
`

const App = () => {
  const location = useLocation()
  const { theme, showHeader } = useLayout()

  return (
    <AppWrapper>
      {showHeader && (
        <AppHeader>
          <NavBar />
        </AppHeader>
      )}
      <AppContent>
        <AnimatePresence mode="wait">
          <Routes location={location}>
            <Route path="/" element={<Scanner />} />
            <Route path="/navigation" element={<Navigation />} />
            <Route path="/new-collection" element={<Landing />} />
            <Route path="/listing/:id" element={<Listing />} />
            <Route path="/search" element={<Search />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/product/:id" element={<Product />} />
          </Routes>
        </AnimatePresence>
      </AppContent>
    </AppWrapper>
  )
}

export default App
