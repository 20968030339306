import styled, { css } from 'styled-components'

import { Reset, TransitionPrimary } from '@/lib/mixins'

export const TabsWrapper = styled.nav`
  white-space: nowrap;
`
export const TabsItem = styled.button`
  ${Reset('button')}
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: 300;
  font-size: 1rem;

  &:before {
    position: absolute;
    left: calc(50% - 1rem);
    width: 2rem;
    bottom: -0.5em;
    height: 1px;
    background: currentColor;
    content: ' ';
    transform: scaleX(0);
    ${TransitionPrimary('transform')}
  }

  ${props =>
    props.active &&
    css`
      font-weight: 600;

      &:before {
        transform: scaleX(1);
      }
    `};

  &:not(:last-child) {
    margin-right: 1rem;
  }
`
