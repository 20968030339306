import { AnimatePresence } from 'framer-motion'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Icon from '@/components/Icon'
import Price from '@/components/Price'

import { CartItemContent, CartItemImage, CartItemRemove, CartItemWrapper } from './Cart.styles'
import CartItemRemoveModal from './CartItemRemoveModal'

const CartItem = ({ product }) => {
  const { id, imageUrl, title, price, qty, oldPrice } = product
  const [showRemoveModal, setShowRemoveModal] = useState(false)
  const { t } = useTranslation()

  return (
    <>
      <CartItemWrapper key={id}>
        <CartItemImage>
          <img src={imageUrl} alt="" />
        </CartItemImage>
        <CartItemContent>
          <h4>{title}</h4>
          <h6>
            {qty} {t('qty')}
          </h6>
          <Price oldPrice={oldPrice}>{price}</Price>

          <CartItemRemove onClick={() => setShowRemoveModal(true)}>
            <Icon name="trash" />
          </CartItemRemove>
        </CartItemContent>
      </CartItemWrapper>
      <AnimatePresence mode="wait">
        {showRemoveModal && <CartItemRemoveModal product={product} onClose={() => setShowRemoveModal(false)} />}
      </AnimatePresence>
    </>
  )
}

export default CartItem
