import styled from 'styled-components'

export const ListingHeader = styled.div`
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  h2 {
    margin-bottom: 0;
    flex: 1 1 auto;
    padding-right: 2rem;
  }
`

export const ListingGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: var(--grid-gap);
`
