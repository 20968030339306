import { CURRENCY_SIGN } from '@/lib/helpers'

import { PriceDefault, PriceOld, PriceWrapper } from './Price.styles'

const Price = ({ children, old }) => {
  return (
    <PriceWrapper>
      {old && (
        <PriceOld>
          {CURRENCY_SIGN}
          {Number(old).toFixed(2)}
        </PriceOld>
      )}
      <PriceDefault featured={!!old}>
        {CURRENCY_SIGN}
        {Number(children).toFixed(2)}
      </PriceDefault>
    </PriceWrapper>
  )
}

export default Price
