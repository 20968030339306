import styled from 'styled-components'

import { ScannerIndicatorWrapper } from '../Scanner/Scanner.styles'

export const SearchHeader = styled.div`
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  h2 {
    margin-bottom: 0;
    flex: 1 1 auto;

    small {
      display: block;
      font-size: 1rem;
    }
  }
`

export const SearchGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: var(--grid-gap);
`

export const SearchEmpty = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  text-align: center;
  flex-direction: column;
  justify-content: center;

  ${SearchHeader} {
    margin-bottom: auto;
  }

  > svg {
    margin-top: auto;
    display: block;
    height: 1.75rem;
    width: 1.75rem;
    margin-bottom: 3rem;
  }

  ${ScannerIndicatorWrapper} {
    margin-top: 4rem;
  }
`
