import Price from '../Price'
import { ProductCardContent, ProductCardImage, ProductCardWrapper } from './ProductCard.styles'

const ProductCard = ({ title, price, oldPrice, imageUrl, onClick }) => {
  return (
    <ProductCardWrapper onClick={onClick}>
      <ProductCardImage>
        <img src={imageUrl} alt={title} />
      </ProductCardImage>
      <ProductCardContent>
        <h4>{title}</h4>
        <Price old={oldPrice}>{price}</Price>
      </ProductCardContent>
    </ProductCardWrapper>
  )
}

ProductCard.displayName = 'ProductCard'

export default ProductCard
