import { useNavigate } from 'react-router'

import ProductCard from '@/components/ProductCard/ProductCard'
import products from '@/data/products.json'
import View from '@/layout/View'

import { LandingCover, LandingProduct, LandingRow, LandingWrapper } from './Landing.styles'

const Landing = () => {
  const navigate = useNavigate()

  return (
    <View showHeader>
      <LandingWrapper>
        <h1>New Collection</h1>
        <LandingProduct>
          <ProductCard {...products[0]}></ProductCard>
        </LandingProduct>
        <LandingCover>
          <img src="/img/IMAGE-landscape-fill-b85ff65c-d3a2-450f-bd3b-3a9df8f81148-default_0.jpg" alt="" />
        </LandingCover>
        <LandingRow>
          <ProductCard {...products[2]}></ProductCard>
          <ProductCard {...products[3]}></ProductCard>
        </LandingRow>
        <LandingCover>
          <img src="/img/IMAGE-landscape-fill-23e0225c-0357-43d6-9000-32598cc15157-default_0.jpg" alt="" />
        </LandingCover>
        <LandingProduct>
          <ProductCard {...products[4]}></ProductCard>
        </LandingProduct>
      </LandingWrapper>
    </View>
  )
}

export default Landing
