import { produce } from 'immer'

import { createSelector, createSlice } from '@reduxjs/toolkit'

// import products from '@/data/products.json'

const initialState = {
  steps: [
    {
      title: 'cart',
      name: 'CART'
    },
    {
      title: 'confirmation',
      name: 'CONFIRMATION'
    }
  ],
  currentStep: 'CART',
  paymentError: null,
  cart: []
  // cart: [...products.map(o => ({ ...o, qty: 1 }))]
}

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    addToCart: (state, { payload }) => {
      const item = state.cart.find(o => o.id === payload.id)

      return item
        ? {
            ...state,
            cart: produce(state.cart, draftState => {
              draftState.find(o => o.id === item.id).qty++
            })
          }
        : {
            ...state,
            cart: [...state.cart, { ...payload, qty: 1 }]
          }
    },
    removeFromCart: (state, { payload }) => {
      const itemToRemove = state.cart.find(o => o.id.toString() === payload.id.toString())

      return itemToRemove && itemToRemove.qty > 1 && !payload.all
        ? {
            ...state,
            cart: produce(state.cart, draftState => {
              draftState.find(o => o.id === itemToRemove.id).qty--
            })
          }
        : {
            ...state,
            cart: state.cart.filter(o => o.id.toString() !== payload.id.toString())
          }
    },
    setCurrentStep: (state, { payload }) => {
      return {
        ...state,
        currentStep: payload
      }
    },
    setPaymentMethod: (state, { payload }) => {
      return {
        ...state,
        paymentMethod: payload
      }
    },
    setPaymentError: (state, { payload }) => {
      return {
        ...state,
        paymentError: payload
      }
    },
    reset: state => {
      return {
        ...initialState
      }
    }
  }
})

const cartItems = state => state.cart

export const { setCurrentStep, addToCart, removeFromCart, setPaymentMethod, setPaymentError, reset } = checkoutSlice.actions

export const cartTotalSelector = createSelector(cartItems, cart =>
  cart.length > 0 ? cart.map(o => o.qty * o.price).reduce((acc, current) => acc + current) : 0
)

export default checkoutSlice.reducer
