import { normalize } from 'polished'
import { createGlobalStyle } from 'styled-components'

import { Reset } from './lib/mixins'

const Style = createGlobalStyle`
  ${normalize()}

  :root {
    --font-primary: 'Helvetica Neue', sans-serif;
    --font-secondary: 'Playfair Display', serif;

    --color-body: #ffffff;
    --color-global: #000000;
    --color-inverted: #ffffff;
    --color-light: #EEEEEE;
    --color-muted: rgba(0,0,0,0.65);
    --color-muted-inverted: rgba(255,255,255,0.5);
    --color-green: #0AB500;
    --color-light: #EFF3F8;
    --color-dark: #000329;
    --color-primary: #FFE800;
    --color-line: #DCE2EC;
    --color-line-inverted: rgba(255,255,255,0.15);
    --color-red: #C91900;

    --size-top-bar: 32px;
    --size-nav-bar: 6rem;
    --size-steps-bar: 40px;
    
    --grid-gap: 3rem;
    
    --view-padding-vertical: 4rem;
    --view-padding-horizontal: 5rem;
  }

  html {
	  font-size: 17px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.35;
    font-family: var(--font-primary);
    color: var(--color-global);
    font-weight: 300;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0.05);
  }

  body {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background-color: var(--color-body);
  }

  * {
    box-sizing: border-box;
  }


  ::-webkit-scrollbar {
    display: none;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 1.5rem;
    font-weight: 800;
    line-height: 1.25;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-weight: 300;
    font-size: 1.2rem;

    strong {
      font-weight: 600;
    }
  }

  h1 {
    font-size: 3.75rem;
    line-height: 1.15;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-weight: 300;
    font-size: 1.4rem;
    margin-bottom: 1.5em;
  }

  h4 {
    font-size: 0.95rem;
    text-transform: uppercase;
  }

  h6 {
    font-size: 0.85rem;
    font-weight: 400;
    color: var(--color-muted);
  }

  a {
    ${Reset('link')}
    color: var(--color-primary);
  }

  p {
    margin-top: 0;
    margin-bottom: 2rem;
    line-height: 1.35;

    strong {
      font-weight: 600;
    }
  }

  img {
    max-width: 100%;
  }

dt {
  opacity: 0.5;
  display: inline-block;
  margin-right: 0.15em;
  &:after {
    content: ':';
  }
}

dd {
  display: inline-block;
  margin: 0 0 0.25em;
  font-weight: 400;
}
`

export default Style
