import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'

import Button from '@/components/Button'
import Icon from '@/components/Icon'
import Price from '@/components/Price'
import products from '@/data/products.json'
import View from '@/layout/View'

import useCheckout from '../Checkout/hooks/useCheckout'
import withScanner from '../Scanner/withScanner'
import {
  ProductDetailsWrapper,
  ProductEmpty,
  ProductGallery,
  ProductMessage,
  ProductMessageContent,
  ProductMessageIcon
} from './Product.styles'

const ProductPopupDetails = ({ product }) => {
  const { t } = useTranslation()

  return (
    <ProductMessage>
      <ProductMessageIcon>
        <Icon name="tick" />
      </ProductMessageIcon>
      <ProductMessageContent>
        <h3>{t('addedToCartTitle')}</h3>
        <p>{t('addedToCartDescription')}</p>
      </ProductMessageContent>
    </ProductMessage>
  )
}

const ProductDetails = ({ product }) => {
  const { title, price, oldPrice } = product

  return (
    <ProductDetailsWrapper>
      <h3>{title}</h3>
      <Price old={oldPrice}>{price}</Price>
      <p>
        {price.toFixed(2)} EUR initial price; {price.toFixed(2)} EUR lowest price from last 30 days before last price reduction;{' '}
        {price.toFixed(2)} EUR discounted price
      </p>
    </ProductDetailsWrapper>
  )
}

const ProductActions = ({ product, onAddToCart }) => {
  const { t } = useTranslation()

  return (
    <>
      <Button block onClick={onAddToCart}>
        {t('addToCart')}
      </Button>
    </>
  )
}

const ProductPopupActions = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const handleCheckout = () => {
    navigate('/checkout')
  }

  return (
    <>
      <Button block onClick={handleCheckout}>
        {t('goToBag')}
      </Button>
    </>
  )
}

const Product = () => {
  const [product, setProduct] = useState({})
  const { id } = useParams()
  const { t } = useTranslation()
  const [showAddedPopup, setShowAddedPopup] = useState(false)
  const { addToCart } = useCheckout()

  const handleAddToCart = () => {
    addToCart(product)
    setShowAddedPopup(true)

    setTimeout(() => {
      setShowAddedPopup(false)
    }, 5000)
  }

  useEffect(() => {
    setProduct(products.find(o => o.id === id))
  }, [id])

  if (!product) return <ProductEmpty>{t('cantFindProduct')}</ProductEmpty>

  if (product.id)
    return (
      <View
        showHeader
        hasFooter
        cover
        showFooterPopup={showAddedPopup}
        footerContent={<ProductDetails product={product} />}
        footerActions={<ProductActions product={product} onAddToCart={handleAddToCart} />}
        footerPopupContent={<ProductPopupDetails />}
        footerPopupActions={<ProductPopupActions />}
        showOverlay={showAddedPopup}
        onOverlayClick={() => setShowAddedPopup(false)}
      >
        {product && (
          <ProductGallery>
            <img src={product.imageUrl} alt="" />
          </ProductGallery>
        )}
      </View>
    )
}

export default withScanner(Product)
