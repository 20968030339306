import styled, { css } from 'styled-components'

import { Cover, Reset, TransitionPrimary } from '@/lib/mixins'

import { ScannerIndicatorWrapper } from '../Scanner/Scanner.styles'

export const NavigationWrapper = styled.div`
  ${Cover()}
  display: flex;
  flex-direction: column;
  padding: calc(var(--size-nav-bar) + var(--view-padding-vertical)) var(--view-padding-horizontal) calc(var(--view-padding-vertical) * 0.75);
  overflow: hidden;

  ${ScannerIndicatorWrapper} {
    margin-top: auto;
  }
`

export const NavigationTabs = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: var(--view-padding-vertical);
  padding-top: 0.5rem;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.05);
`

export const NavigationTabsItem = styled.button`
  ${Reset('button')}
  flex: 1 0 0;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  font-weight: 300;
  font-size: 1.7rem;
  ${TransitionPrimary('font-weight')}

  span {
    position: relative;
    padding: 0 1rem;

    &:before {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -0.25em;
      height: 1px;
      background: currentColor;
      content: ' ';
      transform: scaleX(0);
      ${TransitionPrimary('transform')}
    }
  }

  ${props =>
    props.active &&
    css`
      font-weight: 600;
      span {
        &:before {
          transform: scaleX(1);
        }
      }
    `};
`

export const NavigationList = styled.nav`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  margin: -1rem -1rem 0;
  padding: 1rem 1rem 2rem;
  flex: 1 1 auto;
`

export const NavigationListItem = styled.button`
  position: relative;
  ${Reset('button')}
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  font-weight: 300;
  font-size: 1.2rem;
  padding: 0.2em;

  &:not(:last-child) {
    margin-bottom: 1em;
  }

  ${props =>
    props.featured &&
    css`
      &:before {
        position: absolute;
        top: -0.25em;
        left: -0.65em;
        right: -0.65em;
        bottom: -0.25em;
        background-color: var(--color-primary);
        content: ' ';
        z-index: -1;
      }
    `};
`
