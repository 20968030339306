import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import Button from '@/components/Button'
import View from '@/layout/View'

import useCheckout from '../../hooks/useCheckout'
import { ConfirmationContent, ConfirmationGallery } from './Confirmation.styles'

const Confirmation = () => {
  const { t } = useTranslation()
  const { cart, reset, paymentError, setCurrentStep } = useCheckout()
  const navigate = useNavigate()

  const resetCheckout = () => {
    reset()
    navigate('/')
  }

  // const printConfirmation = () => {
  //   try {
  //     const data = cart.map(o => ({ name: o.title, amount: o.price * o.qty }))
  //     window.BtDevice.printInvoice(JSON.stringify(data))
  //   } catch (error) {
  //     console.error('[DEVICE] printInvoice error', error)
  //   }
  // }

  return (
    <View showHeader cover>
      <ConfirmationContent>
        {/* Success */}
        {!paymentError && (
          <>
            <ConfirmationGallery>
              {cart[0] && (
                <motion.img
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.4 }}
                  src={cart[0].imageUrl}
                  alt=""
                />
              )}
              {cart[1] && (
                <motion.img
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.4 }}
                  src={cart[1].imageUrl}
                  alt=""
                />
              )}
            </ConfirmationGallery>
            <h1>{t('paymentConfirmationTitle')}</h1>
            <h3>{t('paymentConfirmationDescription')}</h3>
            <Button size="lg" onClick={() => resetCheckout()}>
              {t('finish')}
            </Button>
          </>
        )}
        {/* Error */}
        {paymentError && (
          <>
            <svg width="139px" height="138px">
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(4.208529, 4.000000)" stroke="#E43232" strokeWidth="3">
                  <motion.line
                    x1="0"
                    y1="130.073547"
                    x2="130.073547"
                    y2="0"
                    initial={{ pathLength: 0 }}
                    animate={{ pathLength: 1 }}
                    transition={{
                      delay: 0.25,
                      duration: 0.75
                    }}
                  ></motion.line>
                  <motion.line
                    x1="0"
                    y1="130.073547"
                    x2="130.073547"
                    y2="0"
                    transform="translate(65.036774, 65.036774) scale(-1, 1) translate(-65.036774, -65.036774) "
                    initial={{ pathLength: 0 }}
                    animate={{ pathLength: 1 }}
                    transition={{
                      delay: 0.75,
                      duration: 0.75
                    }}
                  ></motion.line>
                </g>
              </g>
            </svg>
            <h1>{t('paymentErrorTitle')}</h1>
            <p>{paymentError}</p>
            <Button size="lg" onClick={() => setCurrentStep('CART')}>
              {t('back')}
            </Button>
          </>
        )}
      </ConfirmationContent>
    </View>
  )
}

export default Confirmation
