import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import Button from '@/components/Button'
import Icon from '@/components/Icon'
import ProductCard from '@/components/ProductCard/ProductCard'
import products from '@/data/products.json'
import View from '@/layout/View'

import ScannerIndicator from '../Scanner/ScannerIndicator'
import { SearchEmpty, SearchGrid, SearchHeader } from './Search.styles'

const Search = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const phrase = searchParams.get('phrase').toLowerCase()

  const computedProducts = useMemo(() => products.filter(o => o.title.toLowerCase().includes(phrase)), [phrase, products])

  if (computedProducts.length === 0)
    return (
      <View showHeader cover>
        {computedProducts.length === 0 && (
          <SearchEmpty>
            <Icon name="search" />
            <SearchHeader>
              <h2>
                <small>No results for:</small>
                <strong>{phrase}</strong>
              </h2>
            </SearchHeader>
            <Button variant="primary" size="lg" onClick={() => navigate('/navigation')}>
              {t('browseProducts')}
            </Button>
            <ScannerIndicator />
          </SearchEmpty>
        )}
      </View>
    )

  return (
    <View showHeader>
      <SearchHeader>
        <h2>
          <small>{computedProducts.length} results for:</small> {phrase}
        </h2>
      </SearchHeader>
      <SearchGrid>
        {computedProducts.map(product => (
          <ProductCard key={product.id} {...product} onClick={() => navigate(`/product/${product.id}`, { replace: true })} />
        ))}
      </SearchGrid>
    </View>
  )
}

export default Search
