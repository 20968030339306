import { useEffect } from 'react'
import Marquee from 'react-fast-marquee'
import { useTranslation } from 'react-i18next'
import SVG from 'react-inlinesvg'
import { useNavigate } from 'react-router'
import 'swiper/css'

import Button from '@/components/Button'
import View from '@/layout/View'

import useCheckout from '../Checkout/hooks/useCheckout'
import { ScannerCta, ScannerLogo, ScannerSlider, ScannerSliderImage, ScannerWrapper } from './Scanner.styles'
import ScannerIndicator from './ScannerIndicator'
import withScanner from './withScanner'

const Scanner = () => {
  const { t } = useTranslation()
  const { reset } = useCheckout()
  const navigate = useNavigate()

  useEffect(() => {
    reset()
  }, [])

  return (
    <View theme="LIGHT">
      <ScannerWrapper>
        <ScannerLogo>
          <SVG src={require('@/assets/img/logo.svg').default}></SVG>
        </ScannerLogo>
        <ScannerSlider>
          <Marquee>
            <ScannerSliderImage variant="a">
              <img src="./img/hero01.jpg" alt=".."></img>
            </ScannerSliderImage>
            <ScannerSliderImage variant="b">
              <img src="./img/hero02.jpg" alt=".."></img>
            </ScannerSliderImage>
            <ScannerSliderImage variant="c">
              <img src="./img/hero03.jpg" alt=".."></img>
            </ScannerSliderImage>
            <ScannerSliderImage variant="d">
              <img src="./img/hero04.jpg" alt=".."></img>
            </ScannerSliderImage>
          </Marquee>
        </ScannerSlider>
        <ScannerCta>
          <Button variant="primary" size="lg" onClick={() => navigate('/navigation')}>
            {t('browseProducts')}
          </Button>
        </ScannerCta>
        <ScannerIndicator />
      </ScannerWrapper>
    </View>
  )
}

export default withScanner(Scanner)
