import styled, { keyframes } from 'styled-components'

import { PriceWrapper } from '@/components/Price/Price.styles'
import { Reset } from '@/lib/mixins'

import { ScannerIndicatorWrapper } from '../Scanner/Scanner.styles'

export const ProductWrapper = styled.div`
  width: 18rem;
  margin: 0 auto;

  ${ScannerIndicatorWrapper} {
    margin-top: 1.25rem;
  }
`
export const ProductGallery = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    display: block;
    height: 100%;
    object-fit: contain;
  }
`

export const ProductImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -1.25rem;
  margin-bottom: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  width: 10rem;
  height: 14rem;
  background-color: var(--color-light);

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
`

export const ProductContent = styled.div`
  h2 {
    margin-bottom: 1rem;
  }
  h6 {
    margin-bottom: 0.25rem;
  }
`

export const ProductDetails = styled.ul`
  ${Reset('list')}
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  font-size: 0.9rem;
  margin-bottom: 1rem;

  li {
    flex: 0 0 auto;

    &:not(:last-child) {
      margin-right: 1.75rem;
    }

    dl {
      margin: 0 0 1rem;
      margin-bottom: 0;
    }
  }
`

export const ProductPrice = styled.h3`
  margin-bottom: 1.25rem;
`

export const ProductEmpty = styled.div`
  max-width: 14rem;
  margin: 20vh auto;
  text-align: center;
  opacity: 0.5;
`

export const ProductDetailsWrapper = styled.div`
  h3 {
    margin-bottom: 1rem;
  }

  ${PriceWrapper} {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 0.8rem;
    line-height: 1.3;
    color: var(--color-muted);
    margin-bottom: 0;
  }
`

export const ProductMessage = styled.div`
  display: flex;
`

const strokeAnimation = keyframes`
  0% { stroke-dashoffset: 200; }
  100% { stroke-dashoffset: 0; }
`

export const ProductMessageIcon = styled.div`
  margin-right: 1rem;
  margin-left: -2.5rem;
  svg {
    width: 2.5rem;
    fill: none;

    path {
      stroke-dasharray: 200;
      stroke-dashoffset: 200;
      animation: ${strokeAnimation} 1.5s ease-in-out 0.25s forwards;
    }
  }
`
export const ProductMessageContent = styled.div`
  h3 {
    margin-bottom: 0.75rem;
  }
`
