import { ButtonBadge, ButtonWrapper } from './Button.styles'

const Button = ({ children, block, variant = 'primary', onClick, badge, size }) => {
  return (
    <ButtonWrapper block={block} variant={variant} size={size} onClick={onClick}>
      {children}
      {badge && <ButtonBadge>{badge}</ButtonBadge>}
    </ButtonWrapper>
  )
}

export default Button
