import { AnimatePresence, motion } from 'framer-motion'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import useCheckout from '@/features/Checkout/hooks/useCheckout'

import useLayout from '../useLayout'
import { ViewBody, ViewBodyOverlay, ViewFooter, ViewFooterCol, ViewFooterPopup, ViewScroller, ViewWrapper } from './View.styles'

const View = ({
  children,
  cover,
  showHeader,
  collapsibleComponent,
  footerContent,
  footerActions,
  hasFooter,
  showFooterPopup,
  footerPopupContent,
  footerPopupActions,
  showOverlay,
  onOverlayClick
}) => {
  const { setShowHeader } = useLayout()
  const { t } = useTranslation()
  const { currentStep } = useCheckout()

  useEffect(() => {
    setShowHeader(!!showHeader)
  }, [])

  return (
    <ViewWrapper as={motion.div} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} hasCollapse={collapsibleComponent}>
      <ViewScroller>
        <ViewBody cover={cover} hasFooter={hasFooter}>
          {children}
          <ViewBodyOverlay onClick={onOverlayClick} show={showOverlay} />
        </ViewBody>
      </ViewScroller>
      {hasFooter && (
        <ViewFooter>
          {footerContent && <ViewFooterCol>{footerContent}</ViewFooterCol>}
          {footerActions && <ViewFooterCol>{footerActions}</ViewFooterCol>}
          <AnimatePresence>
            {showFooterPopup && (
              <ViewFooterPopup
                as={motion.div}
                initial={{ y: '100%' }}
                animate={{ y: '0%' }}
                exit={{ y: '100%' }}
                transition={{
                  duration: 0.3
                }}
              >
                {footerPopupContent && <ViewFooterCol>{footerPopupContent}</ViewFooterCol>}
                {footerPopupActions && <ViewFooterCol>{footerPopupActions}</ViewFooterCol>}
              </ViewFooterPopup>
            )}
          </AnimatePresence>
        </ViewFooter>
      )}
    </ViewWrapper>
  )
}

export default View
