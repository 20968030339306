import { TabsItem, TabsWrapper } from './Tabs.styles'

const Tabs = ({ items }) => {
  return (
    <TabsWrapper>
      {items.map(item => (
        <TabsItem key={item.label} onClick={item.onClick} active={item.active}>
          {item.label}
        </TabsItem>
      ))}
    </TabsWrapper>
  )
}

export default Tabs
