import styled from 'styled-components'

import { PriceOld, PriceWrapper } from '../Price/Price.styles'

export const ProductCardWrapper = styled.div``
export const ProductCardImage = styled.div`
  img {
    display: block;
    width: 100%;
  }
`
export const ProductCardContent = styled.div`
  padding: 1rem 0 0;
  display: flex;

  ${PriceWrapper} {
    text-align: right;
  }

  ${PriceOld} {
    display: block;
  }

  h4 {
    margin-bottom: 0;
    padding-right: 1rem;
    flex: 1 1 auto;
  }
`
