import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import categories from '@/data/categories.json'
import View from '@/layout/View'

import ScannerIndicator from '../Scanner/ScannerIndicator'
import withScanner from '../Scanner/withScanner'
import { NavigationList, NavigationListItem, NavigationTabs, NavigationTabsItem, NavigationWrapper } from './Navigation.styles'

const Navigation = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState(1)
  const [activeCategory, setActiveCategory] = useState(1)

  const tabs = [
    {
      id: 1,
      name: 'Man'
    },
    {
      id: 2,
      name: t('woman')
    },
    {
      id: 3,
      name: t('kids')
    }
  ]

  return (
    <View theme="LIGHT" showHeader>
      <NavigationWrapper>
        <NavigationTabs>
          {tabs.map(tab => (
            <NavigationTabsItem key={tab.id} active={activeTab === tab.id} onClick={() => setActiveTab(tab.id)}>
              <span>{tab.name}</span>
            </NavigationTabsItem>
          ))}
        </NavigationTabs>
        <NavigationList>
          {categories.map(category => (
            <NavigationListItem
              key={category.id}
              featured={category.featured}
              onClick={() => (category.featured ? navigate('/new-collection') : navigate(`/listing/${category.id}`))}
            >
              {category.name}
            </NavigationListItem>
          ))}
        </NavigationList>
        <ScannerIndicator />
      </NavigationWrapper>
    </View>
  )
}

export default withScanner(Navigation)
