import styled from 'styled-components'

export const LandingWrapper = styled.div`
  h1 {
    text-align: center;
    margin: 0 auto;
    max-width: 30rem;
    margin-bottom: -2rem;
    z-index: 2;
    position: relative;
  }
`

export const LandingProduct = styled.div`
  margin: 0 auto;
  max-width: 26rem;
`

export const LandingCover = styled.div`
  margin: var(--view-padding-vertical) calc(var(--view-padding-horizontal) * -1);

  img {
    display: block;
    width: 100%;
  }
`

export const LandingRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: var(--grid-gap);

  > * {
    &:nth-child(2n) {
      margin-top: calc(var(--view-padding-vertical) * 2);
    }
  }
`
