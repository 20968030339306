import { useEffect, useRef } from 'react'
import SVG from 'react-inlinesvg'
import { useLocation, useNavigate } from 'react-router'

import Icon from '@/components/Icon'
import useCheckout from '@/features/Checkout/hooks/useCheckout'

import { NavBarBack, NavBarCart, NavBarLogo, NavBarSearch, NavBarWrapper } from './NavBar.styles'

const NavBar = () => {
  const navigate = useNavigate()
  const { cart, currentStep } = useCheckout()
  const location = useLocation()
  const searchInputRef = useRef(null)

  const handleBack = () => {
    if (location.pathname === '/checkout') {
      if (currentStep === 'CONFIRMATION') navigate('/')
      else navigate('/navigation')
    } else if (location.pathname === '/navigation') navigate('/')
    else navigate(-1)
  }

  const handleSearch = e => {
    e.preventDefault()
    const val = e.target.elements.phrase.value
    navigate(`/search?phrase=${val}`)
  }

  useEffect(() => {
    searchInputRef.current.blur()
    searchInputRef.current.value = ''
  }, [location])

  return (
    <NavBarWrapper>
      <NavBarBack onClick={handleBack}>
        <Icon name="arrow-left"></Icon>
      </NavBarBack>
      <NavBarLogo>
        <SVG src={require('@/assets/img/logo.svg').default} />
      </NavBarLogo>
      <NavBarSearch>
        <form onSubmit={handleSearch}>
          <Icon name="search"></Icon>
          <input ref={searchInputRef} name="phrase" type="text" placeholder="Search" />
        </form>
      </NavBarSearch>
      {currentStep !== 'CONFIRMATION' && (
        <NavBarCart onClick={() => navigate('/checkout')}>
          <Icon name="cart"></Icon>
          {cart.length}
        </NavBarCart>
      )}
    </NavBarWrapper>
  )
}

export default NavBar
