import styled, { css } from 'styled-components'

export const PriceWrapper = styled.div`
  letter-spacing: 0.05em;
  font-weight: 500;
`

export const PriceDefault = styled.span`
  ${props =>
    props.featured &&
    css`
      background-color: var(--color-primary);
      padding: 0.1em 0.15em;
      margin-left: 0.4em;
    `};
`
export const PriceOld = styled.span`
  text-decoration: line-through;
  font-weight: 400;
  font-size: 0.9em;
`
