import styled from 'styled-components'

import { Reset } from '@/lib/mixins'

export const CartWrapper = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const CartTable = styled.div`
  margin-bottom: 0.25rem;
`

export const CartFooter = styled.div`
  margin-top: auto;
`

export const CartSummary = styled.div`
  margin-bottom: 1.25rem;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 400;
`

export const CartItemWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  margin-bottom: 1rem;

  border-bottom: 1px solid var(--color-line);
`

export const CartItemImage = styled.div`
  flex: 0 0 9rem;

  img {
    display: block;
    width: 100%;
  }
`
export const CartItemRemove = styled.button`
  ${Reset('button')}
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.25rem;

  svg {
    width: 1.5rem;
    fill: currentColor;
    display: block;
  }
`

export const CartItemContent = styled.div`
  position: relative;
  flex: 1 1 auto;
  padding-left: 2rem;
  padding-right: 2rem;
  h6 {
    margin-bottom: 1rem;
  }

  h4 {
    font-size: 1.1rem;
    margin-bottom: 0.25rem;
  }
`

export const CartItemTools = styled.div`
  display: flex;
  align-items: center;
`

export const CartEmpty = styled.div`
  max-width: 14rem;
  margin: 20vh auto;
  text-align: center;
  opacity: 0.5;
`

export const CartItemPrice = styled.h3`
  margin-bottom: 0;
  margin-right: auto;
`

export const CartModalImages = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 3rem;
    margin: 0 0.15rem;
  }
`

export const CartSummaryWrapper = styled.div`
  h3 {
    margin-bottom: 1.5rem;
  }
  h2 {
    font-size: 3rem;
    letter-spacing: 0;
    margin-bottom: 0;
  }
  p {
    font-size: 0.8rem;
    color: var(--color-muted);
    margin-bottom: 0;
  }
`
