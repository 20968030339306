import styled, { css } from 'styled-components'

import { Cover, TransitionPrimary } from '@/lib/mixins'

export const ViewWrapper = styled.div`
  ${Cover()}
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

export const ViewScroller = styled.div`
  flex: 1 1 auto;
  overflow: auto;
  position: relative;
`

export const ViewBodyOverlay = styled.div`
  ${Cover()}
  background-color: rgba(0,0,0,0.1);
  z-index: 3;
  ${TransitionPrimary('opacity, visibility')}
  opacity: 0;
  visibility: hidden;

  ${props =>
    props.show &&
    css`
      opacity: 1;
      visibility: visible;
    `};
`

export const ViewBody = styled.div`
  position: relative;
  min-height: 100%;
  /* display: flex; */
  padding: calc(var(--size-nav-bar) + var(--view-padding-vertical)) var(--view-padding-horizontal) var(--view-padding-vertical);

  ${props =>
    props.middle &&
    css`
      align-items: center;
    `};

  ${props =>
    props.cover &&
    css`
      height: 100%;
      overflow: hidden;
    `};
`

export const ViewFooter = styled.div`
  position: relative;
  display: flex;
  padding: var(--view-padding-vertical) var(--view-padding-horizontal);
  border-top: 1px solid var(--color-global);
  justify-content: space-between;

  > * {
    flex: 0 0 45%;
  }
`

export const ViewFooterCol = styled.div`
  flex: 0 0 45%;

  &:first-child {
    flex: 0 0 50%;
  }
`

export const ViewFooterPopup = styled.div`
  ${Cover()}
  top: -1px;
  display: flex;
  padding: var(--view-padding-vertical) var(--view-padding-horizontal);
  border-top: 1px solid var(--color-global);
  justify-content: space-between;
  background-color: var(--color-body);
`
