import styled, { css, keyframes } from 'styled-components'

import { Cover } from '@/lib/mixins'

const barCodeAnimation = keyframes`
  0% { top: 0; opacity: 0; }
  30% { top: 0; opacity: 0; }
  50% {  opacity: 0.4; }
  70% { top: 100%; opacity: 0; }
  100% { top: 100%; opacity: 0; }
`

export const ScannerIndicatorIcon = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  height: 3rem;
  margin-bottom: 1.5rem;

  &:after {
    position: absolute;
    top: 0;
    height: 2px;
    left: 0;
    right: 0;
    content: ' ';
    background-color: currentColor;
    animation: ${barCodeAnimation} infinite 3s;
  }

  svg {
    display: block;
    width: 80%;
    height: 80%;
  }
`

export const ScannerIndicatorWrapper = styled.div`
  text-align: center;
  position: relative;

  h5 {
    font-size: 0.95rem;
    text-transform: uppercase;
    font-weight: 300;
    margin-bottom: 0;
  }

  ${props =>
    props.size === 'small' &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1rem;

      ${ScannerIndicatorIcon} {
        margin-bottom: 0;
        width: 2.5rem;
        height: 2rem;
        margin-right: 0.5rem;

        &:after {
          height: 1px;
        }
      }
    `};
`

export const ScannerSlider = styled.div`
  margin-top: auto;
  margin: 7vh -1.25rem auto;

  .marquee-container {
    .marquee {
      display: flex;
    }
  }
`

export const ScannerSliderImage = styled.div`
  margin-top: auto;
  width: 100%;
  height: 40vh;
  display: flex;
  padding: 0 5vw;

  img {
    display: block;
    height: 95%;
    width: 100%;
    object-fit: contain;
  }

  ${props =>
    props.variant === 'a' &&
    css`
      img {
        height: 50%;
        margin-top: 30%;
      }
    `};

  ${props =>
    props.variant === 'b' &&
    css`
      img {
        margin-bottom: auto;
      }
    `};

  ${props =>
    props.variant === 'c' &&
    css`
      img {
        height: 50%;
        margin-top: 20%;
      }
    `};

  ${props =>
    props.variant === 'd' &&
    css`
      img {
        margin-top: auto;
      }
    `};
`

export const ScannerLogo = styled.div`
  svg {
    height: 7rem;
    margin: 0 auto;
    display: block;
  }
`

export const ScannerWrapper = styled.div`
  ${Cover()}
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 8vh;
  padding-bottom: calc(var(--view-padding-vertical) * 0.75);

  ${ScannerIndicatorWrapper} {
    margin-top: auto;
  }
`

export const ScannerCta = styled.div`
  text-align: center;
`

const arrowAnimation = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(0.75rem); }
  100% { transform: translateY(0); }
`
export const ScannerIndicatorArrow = styled.div`
  margin-top: 0.75rem;
  animation: ${arrowAnimation} 2s infinite;

  path {
    fill: none;
  }
`
