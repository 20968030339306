import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'

import ProductCard from '@/components/ProductCard/ProductCard'
import Tabs from '@/components/Tabs'
import categories from '@/data/categories.json'
import products from '@/data/products.json'
import View from '@/layout/View'

import { ListingGrid, ListingHeader } from './Listing.styles'

const Listing = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { t } = useTranslation()
  const [lastChanceOnly, setLastChanceOnly] = useState(false)
  const category = categories.find(o => o.id === Number(id))
  const computedProducts = useMemo(() => (lastChanceOnly ? products.filter(o => o.lastChance) : products), [lastChanceOnly, id, products])

  return (
    <View showHeader>
      <ListingHeader>
        <h2>{category?.name}</h2>
        <Tabs
          items={[
            { label: t('viewAll'), onClick: () => setLastChanceOnly(false), active: !lastChanceOnly },
            { label: t('lastChance'), onClick: () => setLastChanceOnly(true), active: lastChanceOnly }
          ]}
        />
      </ListingHeader>
      <ListingGrid>
        {computedProducts.map(product => (
          <ProductCard key={product.id} {...product} onClick={() => navigate(`/product/${product.id}`, { replace: true })} />
        ))}
      </ListingGrid>
    </View>
  )
}

export default Listing
